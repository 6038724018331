import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { Checkbox, SimplifiedInput, SimplifiedTextarea } from '../../../components/reusables/index.js';
import { Gtm } from '../../../utils/gtm.js';

import { default as validate } from '../TellUsAboutYou/ValidateTellUsAboutYou/validateTellUsAboutYou.js';
import { getCookie } from '../../../utils/helpers.js';

import consts from '../../../utils/consts.js';
import { initialFetching } from '../../../utils/initialFetching.js';

import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper.js';

import 'materialize-css';
import TellUsAboutYouRoleDropdownInputNew from '../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleDropdownInput/TellUsAboutYouRoleDropdownInputNew.react.js';
import SelectCustom from '../../../components/reusables/form/SelectCustom.js';
import Slider from './Slider.react.js';
const { PROMOTION_METHODS } = consts;
const PromoCode = getCookie('PromoCode');
import './BusinessSignupForm.scss';
import RadioButtonCustom from '../../../components/reusables/form/RadioButtonCustom';
import IdentityIcon from './svg/identityIcon.react';
import { SliderProvider } from './SliderContext.react.js';
import SignupFormSlide from './SignupFormSlide.react.js';
import provincesList from '../../../utils/list-options/provinces.js';
import statesList from '../../../utils/list-options/states.js';
import TellUsAboutYouRoleInput from '../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleInput/TellUsAboutYouRoleInput.react.js';
import { getBlackLogoFromSkinName } from '../../../utils/logoHelper';
const BusinessSignupForm = ({
  countriesList,
  countryCode,
  dispatch,
  fields,
  handleSubmit,
  hoQuestions,
  intl,
  onSubmit,
  userInfo,
  values,
  verticals,
}) => {
  const [previousUrl, setPreviousUrl] = useState(null);
  let slideNumber = 0;
  const [subDivisionShown, setSubdivisionShown] = useState(1);
  useEffect(() => {
    initialFetching(dispatch);

    const storedUrl = localStorage.getItem('previousUrl');
    if (storedUrl) {
      setPreviousUrl(storedUrl);
    }
  }, []);

  const concurrentCalls = () => {
    if (previousUrl) {
      Gtm.event('referrer', 'trackSignUp', previousUrl);
      window.localStorage.removeItem('previousUrl');
    }
    handleSubmit(onSubmit);
  };
  useEffect(() => {
    if (userInfo.acceptTOS) {
      dispatch(change('BusinessSignupForm', 'acceptTOS', userInfo.acceptTOS));
    }
    if (userInfo.company) {
      dispatch(change('BusinessSignupForm', 'company', userInfo.company));
    }
    if (userInfo.promo_code && !PromoCode) {
      dispatch(change('BusinessSignupForm', 'promo', userInfo.promo_code));
    }
  }, [userInfo.acceptTOS, userInfo.company, userInfo.promo_code]);

  useEffect(() => {
    if (!userInfo.company) {
      if (hoQuestions?.signupQuestionKeys?.length && !hoQuestions.signupQuestionKeys.find((e) => e === 'affiliateType')) {
        hoQuestions.signupQuestionKeys = ['affiliateType', ...hoQuestions.signupQuestionKeys];
      }
    }
  }, [hoQuestions.signupQuestionKeys, userInfo.company]);

  const LinkTerms = (
    <a href="https://www.crakrevenue.com/tos/" rel="noreferrer" target="_blank">
      <FormattedMessage {...messages.createAccountToSLinkToU} />
    </a>
  );
  const LinkPrivacy = (
    <a href="http://www.crakrevenue.com/privacy/" rel="noreferrer" target="_blank">
      <FormattedMessage {...messages.createAccountToSLinkPP} />
    </a>
  );
  messages.createAccountToSLink.values = { LinkTerms, LinkPrivacy };

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countriesList,
    ExcludedCountriesHelper.getSignUpExcludeCountries()
  );

  const addField = () => {
    setSubdivisionShown(subDivisionShown + 1);
  };
  const getRoleSubdivision = (role) => {
    switch (role) {
      case 'Webmaster':
        return (
          <div>
            <p className="custom-label">Your top website URL(s)</p>
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website1}
              hidden={!(subDivisionShown >= 1)}
              placeholder="https://www.mywebsite.com"
              showAdd={fields.website1.value?.length > 0 && fields.website1.valid ? subDivisionShown === 1 : null}
              tabIndex={-1}
            />
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website2}
              hidden={!(subDivisionShown >= 2)}
              placeholder="https://www.mywebsite.com"
              showAdd={fields.website2.value?.length > 0 && fields.website2.valid ? subDivisionShown === 2 : null}
              tabIndex={-1}
            />
            <TellUsAboutYouRoleInput
              addField={addField}
              field={fields.website3}
              hidden={!(subDivisionShown >= 3)}
              placeholder="https://www.mywebsite.com"
              showAdd={false}
              tabIndex={-1}
            />
          </div>
        );
      case 'Social Marketer':
      case 'Influencer / Content Creator':
        return (
          <div>
            <p className="custom-label">Your top social account URL(s)</p>
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled
              fieldInput={fields.handle1}
              fieldSelect={fields.social1}
              hidden={!(subDivisionShown >= 1)}
              inputOptions="socials"
              showAdd={
                fields.social1.value?.length > 0 && fields.handle1.value?.length > 0 && fields.handle1.valid
                  ? subDivisionShown === 1
                  : null
              }
              tabIndex={-1}
            />
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled={false}
              fieldInput={fields.handle2}
              fieldSelect={fields.social2}
              hidden={!(subDivisionShown >= 2)}
              inputOptions="socials"
              showAdd={
                fields.social2.value?.length > 0 && fields.handle2.value?.length > 0 && fields.handle2.valid
                  ? subDivisionShown === 2
                  : null
              }
              tabIndex={-1}
            />
            <TellUsAboutYouRoleDropdownInputNew
              addField={addField}
              disabled={false}
              fieldInput={fields.handle3}
              fieldSelect={fields.social3}
              hidden={!(subDivisionShown >= 3)}
              inputOptions="socials"
              tabIndex={-1}
            />
          </div>
        );
      case 'E-Mailer':
        return;
      case 'Media Buyer':
        return (
          <TellUsAboutYouRoleInput
            field={fields.budget}
            inputmode="numeric"
            label={messages.tellUsYourRoleMediaBuyer}
            normalizeBudget
            placeholder="$5,000"
            tabIndex={-1}
            type="text"
          />
        );
      case 'Affiliate Network':
      case 'Ad Network':
        return (
          <TellUsAboutYouRoleInput
            field={fields.network}
            label={messages.tellUsYourRoleNetwork}
            placeholder="https://www.myadnetwork.com"
            tabIndex={-1}
          />
        );
    }
  };

  const affiliateTypeOptions = ['Individual', 'Company'];
  const trafficMonetizationOptions = ['New', 'Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const monthlyRevenueOptions = ['$0-$100', '$101-$500', '$501-$2,500', '$2,501-$10,000', 'more than $10,000'];
  const getInputByQuestions = (questions) => {
    switch (questions) {
      case 'affiliateType':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.company.error && fields.affiliateType.value}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'Do you register as an individual or a company?',
                  question_response: fields.affiliateType.value,
                  country: countryCode,
                },
              },
            ]}
          >
            {!userInfo.company && (
              <>
                <RadioButtonCustom
                  containerClass="affiliateType"
                  field={fields.affiliateType}
                  id="tellUsMore simplified_affiliateType"
                  key="affiliateType"
                  label="Do you register as an individual or a company?"
                  onChange={fields.affiliateType.onChange}
                  options={affiliateTypeOptions}
                  tabIndex={-1}
                />
                {fields.affiliateType.value === 'Company' ? (
                  <div className="field">
                    <SimplifiedInput
                      autofocus
                      className="simple-signup__input"
                      field={fields.company}
                      id="tellUsMore simplified_company"
                      label={messages.createAccountCompany}
                      labelClasses="company"
                      tabIndex={-1}
                      type="text"
                    />
                  </div>
                ) : null}
              </>
            )}
          </SignupFormSlide>
        );
      case 'accountType':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={
              !fields.accountType.error &&
              !(
                fields.social1.error ||
                fields.website1.error ||
                fields.website2.error ||
                fields.website3.error ||
                fields.social1.error ||
                fields.social2.error ||
                fields.social3.error ||
                fields.handle1.error ||
                fields.handle2.error ||
                fields.handle3.error ||
                fields.budget.error ||
                fields.network.error
              )
            }
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsYourRole.description,
                  question_response: fields.accountType.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <>
              <SelectCustom
                error={fields.accountType.error}
                id="tellUsMore-PromotionMethods"
                isNewFlow
                label={messages.tellUsYourRole.description}
                onChange={fields.accountType.onChange}
                options={PROMOTION_METHODS.map((promotionMethod) => ({ value: promotionMethod, text: promotionMethod }))}
                styleBox
                tabIndex={-1}
                touched={fields.accountType.touched}
                value={fields.accountType.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
              <div className="field">{getRoleSubdivision(values.accountType)}</div>
            </>
          </SignupFormSlide>
        );
      case 'vertical':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.vertical.error}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'Please pick your top vertical(s)?',
                  question_response: fields.vertical.value?.join(','),
                  country: countryCode,
                },
              },
            ]}
          >
            <SelectCustom
              error={fields.vertical.error}
              id="tellUsMore-VerticalPromotion"
              isMulti
              isNewFlow
              label="Please pick your top vertical(s)?"
              maxSelected={2}
              onChange={fields.vertical.onChange}
              options={verticals.map((vertical) => ({ value: vertical.name, text: vertical.name }))}
              styleBox
              tabIndex={-1}
              touched={fields.vertical.touched}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </SignupFormSlide>
        );
      case 'traffic':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.traffic.error}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'What are the top countries you promote offers in?',
                  question_response: fields.traffic.value?.join(','),
                  country: countryCode,
                },
              },
            ]}
          >
            <SelectCustom
              enableSearch
              error={fields.traffic.error}
              id="tellUsMore-VisitorsRegion"
              isMulti
              isNewFlow
              label="What are the top countries you promote offers in?"
              maxSelected={3}
              onChange={fields.traffic.onChange}
              options={filteredCountries.map((country) => ({ value: country.code, text: country.name }))}
              styleBox
              tabIndex={-1}
              touched={fields.traffic.touched}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </SignupFormSlide>
        );
      case 'expertiseLevel':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.expertiseLevel.error && !!fields.expertiseLevel.value}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'What is your level of expertise in traffic monetization?',
                  question_response: fields.expertiseLevel.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <RadioButtonCustom
              className="col-md-12"
              field={fields.expertiseLevel}
              id="tellUsMore simplified_anythingToAdd"
              key={fields.expertiseLevel.name}
              label="What is your level of expertise in traffic monetization?"
              maxLength={1000}
              onChange={fields.expertiseLevel.onChange}
              options={trafficMonetizationOptions}
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'otherNetworks':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.otherNetworks.error && !!fields.otherNetworks.value}
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsOtherNetworks.description,
                  question_response: fields.otherNetworks.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <SimplifiedInput
              autofocus
              className="simple-signup__input businessSignupSimpleInput"
              field={fields.otherNetworks}
              id="tellUsMore simplified_promo-code"
              key="other network"
              label={messages.tellUsOtherNetworks}
              placeholder="Type your message..."
              tabIndex={-1}
              type="text"
            />
          </SignupFormSlide>
        );
      case 'affiliateAchievements':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.affiliateAchievements.error && !!fields.affiliateAchievements.value}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsaffiliateAchievements.description,
                  question_response: fields.affiliateAchievements.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <SimplifiedTextarea
              className="col-md-12"
              field={fields.affiliateAchievements}
              followCharNumber
              id="tellUsMore simplified_anythingToAdd"
              key={fields.affiliateAchievements.name}
              label={messages.tellUsaffiliateAchievements}
              maxLength={1000}
              placeholder="Type you message..."
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'monthlyRevenue':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.monthlyRevenue.error && !!fields.monthlyRevenue.value}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'Current Monthly Revenues from promoting offers',
                  question_response: fields.monthlyRevenue.value?.length,
                  country: countryCode,
                },
              },
            ]}
          >
            <RadioButtonCustom
              className="col-md-12"
              field={fields.monthlyRevenue}
              id="monthlyRevenue"
              key={fields.monthlyRevenue.name}
              label="Current Monthly Revenues from promoting offers"
              onChange={fields.monthlyRevenue.onChange}
              options={monthlyRevenueOptions}
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'anythingToAdd':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.anythingToAdd.error}
            key={questions}
            title="About your business"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsMore.description,
                  question_response: fields.anythingToAdd.value?.length,
                  country: countryCode,
                },
              },
            ]}
          >
            <SimplifiedTextarea
              className="col-md-12"
              field={fields.anythingToAdd}
              followCharNumber
              id="tellUsMore simplified_anythingToAdd"
              key={fields.anythingToAdd.name}
              label={messages.tellUsMore}
              maxLength={1000}
              placeholder="Ex: Experience, more details about your traffic, offers you already promote, additional website URLs, references, etc.)"
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'idCheckWarning':
        return (
          <SignupFormSlide
            ctaText="Understood"
            index={slideNumber++}
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `youform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'Attention: ID Verification may be required',
                  seen_id_warning: true,
                  country: countryCode,
                },
              },
            ]}
          >
            <div className="identity-warning">
              <div className="identity-warning-header">
                <div className="icon-box">
                  <IdentityIcon />
                </div>
                <div className="warning-text">
                  <label>Attention:</label>
                  <span>ID Verification may be required</span>
                </div>
              </div>
              <p>
                Please note that all users are required to scan a valid ID before getting their first payment. You'll need to do
                this once you reach $25 in earnings. Meanwhile, you can continue with the sign-up process.
              </p>
            </div>
          </SignupFormSlide>
        );
      case 'country':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={
              !fields.country.error &&
              (values.country === 'CA' ? !fields.province.error : values.country === 'US' ? !fields.state.error : true)
            }
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `businessform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsCountry.description,
                  question_response: fields.country.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <SelectCustom
              enableSearch
              error={fields.country.error}
              id="tellUsMore-Country"
              label={messages.tellUsCountry.description}
              onChange={fields.country.onChange}
              options={filteredCountries.map((country) => ({ value: country.code, text: country.name }))}
              styleBox
              tabIndex={-1}
              touched={fields.country.touched}
              value={fields.country.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
            {values.country === 'CA' && (
              <SelectCustom
                error={fields.province.error}
                id="tellUsMore-Province"
                label={messages.genericTextProvince.description}
                onChange={fields.province.onChange}
                options={provincesList.map((province) => ({
                  value: province.value,
                  text: intl.formatMessage(messages[province.label.id]),
                }))}
                styleBox
                tabIndex={-1}
                touched={fields.province.touched}
                value={fields.province.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            )}
            {values.country === 'US' && (
              <SelectCustom
                error={fields.state.error}
                id="tellUsMore-Province"
                label={messages.genericTextState.description}
                onChange={fields.state.onChange}
                options={statesList.map((state) => ({
                  value: state.value,
                  text: intl.formatMessage(messages[state.label.id]),
                }))}
                styleBox
                tabIndex={-1}
                touched={fields.state.touched}
                value={fields.state.value || ''}
                valueDisabled={intl.formatMessage(messages.genericTextChoose)}
              />
            )}
          </SignupFormSlide>
        );
      case 'phone':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.phone.error}
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `youform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsPhoneNumber.description,
                  question_response: fields.phone.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <SimplifiedInput
              autofocus
              className="simple-signup__input businessSignupSimpleInput"
              field={fields.phone}
              id="tellUsMore simplified_phone-number"
              inputmode="numeric"
              label={messages.tellUsPhoneNumber}
              placeholder="(+1) Your number"
              tabIndex={-1}
              type="text"
            />
          </SignupFormSlide>
        );
      case 'messagingPlatform':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.messaging_platform_username.error}
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `youform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'Instant Messaging Platform of Choice',
                  question_response: fields.messaging_platform_name.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <TellUsAboutYouRoleDropdownInputNew
              disabled
              fieldInput={fields.messaging_platform_username}
              fieldSelect={fields.messaging_platform_name}
              inputDisabled={!fields.messaging_platform_name.value}
              inputOptions="messaging_platform_name"
              label="Instant Messaging Platform of Choice"
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'howYouHear':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!fields.specify.error && !!fields.howYouHear.value}
            key={questions}
            title="About you"
            trackingEvents={[
              {
                eventName: `youform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: 'How did you hear about us?',
                  question_response: fields.howYouHear.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <TellUsAboutYouRoleDropdownInputNew
              disabled
              fieldInput={fields.specify}
              fieldSelect={fields.howYouHear}
              inputDisabled={!fields.howYouHear.value}
              inputOptions="references"
              label="How did you hear about us?"
              tabIndex={-1}
            />
          </SignupFormSlide>
        );
      case 'promo':
        return (
          <SignupFormSlide
            index={slideNumber++}
            isValid={!!fields.acceptTOS.value && !fields.promo.error}
            key={questions}
            nextFunction={concurrentCalls}
            title="About you"
            trackingEvents={[
              {
                eventName: `youform_${hoQuestions.name}`,
                conversion: false,
                eventParameters: {
                  question_title: messages.tellUsPromoCode.description,
                  question_response: fields.promo.value,
                  country: countryCode,
                },
              },
            ]}
          >
            <SimplifiedInput
              autofocus
              className="simple-signup__input businessSignupSimpleInput"
              field={fields.promo}
              id="tellUsMore simplified_promo-code"
              key="promo code"
              label={messages.tellUsPromoCode}
              optional
              placeholder="Enter your promo code here..."
              tabIndex={-1}
              type="text"
            />
            {!userInfo.acceptTOS && (
              <div className="row" id="simplified_accept_tos">
                <Checkbox
                  className="simple-signup__checkbox"
                  escapeHtml
                  field={fields.acceptTOS}
                  id="create-account-accept-terms"
                  label={messages.createAccountToSLink}
                  tabIndex={-1}
                />
              </div>
            )}
          </SignupFormSlide>
        );
    }
  };

  return (
    <div id="simplified-BusinessSignupForm">
      <div className="customSelects">
        <div className="fields">
          <div className="header">
            <div className="col-md-4">
              <img alt="affiliates" height="35px" src={getBlackLogoFromSkinName('affiliates')} />
            </div>
          </div>
          <SliderProvider>
            <Slider fields={fields}>{hoQuestions?.signupQuestionKeys?.map((question) => getInputByQuestions(question))}</Slider>
          </SliderProvider>
        </div>
      </div>
    </div>
  );
};

BusinessSignupForm.propTypes = {
  countriesList: PropTypes.array.isRequired,
  countryCode: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  values: PropTypes.object.isRequired,
  verticals: PropTypes.array.isRequired,
};

export default reduxForm({
  form: 'BusinessSignupForm',
  touchOnBlur: true,
  touchOnChange: false,
  fields: [
    'acceptTOS',
    'accountType',
    'affiliateType',
    'budget',
    'network',
    'website1',
    'website2',
    'website3',
    'social1',
    'social2',
    'social3',
    'handle1',
    'handle2',
    'handle3',
    'company',
    'country',
    'offer',
    'phone',
    'promo',
    'province',
    'state',
    'traffic',
    'vertical',
    'anythingToAdd',
    'howYouHear',
    'specify',
    'messaging_platform_name',
    'messaging_platform_username',
    'expertiseLevel',
    'otherNetworks',
    'affiliateAchievements',
    'monthlyRevenue',
  ],
  initialValues: {
    acceptTOS: false,
    promo: PromoCode || '',
    website1: '',
    website2: '',
    website3: '',
    social1: '',
    social2: '',
    social3: '',
    handle1: '',
    handle2: '',
    handle3: '',
    vertical: [],
    traffic: [],
  },
  validate: (values) => validate(values, true),
})(BusinessSignupForm);
