import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import messages from '../i18n/base-en.js';

import {
  HomeAccountManager,
  HomeBlog,
  HomeDailyStatistics,
  HomeFeaturedOffers,
  HomePromotion,
  HomeRanking,
  HomeSurvey,
} from './home';
import { Container, NewsBox, PaymentHistoryWidget, PaymentHistoryWidgetContainer } from '../components/reusables';
import Star from '../components/reusables/svg/Star.react';
import PaymentHistoryGraph from '../components/reusables/PaymentHistoryGraph.react';

import { fetchDailyStatistics } from '../components/Home/actions/home-actions';

import { rowsResize } from '../utils/helpers';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { Gtm } from '../utils/gtm';

import config from '../config';

const { NPS } = config;

class Home extends Component {
  constructor(props) {
    super(props);

    const { skinConfigurations } = this.props;

    this.skinConfigurations = skinConfigurations;
    this.handleNewDateRangeStatistics = this.handleNewDateRangeStatistics.bind(this);

    this.cols = {
      3000: {
        1: ['promotion', 'ranking', 'payment', 'promotionImage'],
        2: ['statistics', 'affManager'],
        3: ['blog', 'featuredOffers'],
      },
      1500: {
        1: ['promotion', 'ranking', 'payment', 'promotionImage'],
        3: ['blog', 'featuredOffers'],
        2: ['statistics', 'affManager'],
      },
      992: {
        1: ['promotion', 'promotionImage'],
        2: ['ranking', 'payment'],
        4: ['featuredOffers', 'blog'],
        3: ['statistics', 'affManager'],
      },
      768: {
        1: ['promotion', 'promotionImage'],
        2: ['ranking', 'payment'],
        3: ['featuredOffers'],
        4: ['blog'],
        5: ['statistics', 'affManager'],
      },
      0: {
        1: ['promotion', 'promotionImage'],
        2: ['ranking'],
        3: ['payment'],
        4: ['featuredOffers'],
        5: ['blog'],
        6: ['statistics'],
        7: ['affManager'],
      },
    };
    this.colsElements = {};
    this.rowResize = rowsResize.bind(this, this.cols, this.colsElements);
  }

  UNSAFE_componentWillMount() {
    this.skin = new SkinConfigurationsUtils(this.skinConfigurations);
    this.skin.manageAccess(browserHistory, 'dashboard');
  }

  componentDidMount() {
    this.rowResize();
    window.addEventListener('resize', this.rowResize);
  }

  componentDidUpdate() {
    this.rowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.rowResize);
  }

  getHomeItemsVisibility() {
    const SKIN_COMPONENT_NAME = 'Dashboard';
    return {
      homeAccountManager: {
        container: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeAccountManager'),
        'sub-elements': {
          whale: this.skin.isItemVisible('HomeAccountManager', 'Whale'),
        },
      },
      homeBlog: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeBlog'),
      homeDailyStatistics: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeDailyStatistics'),
      homeFeaturedOffers: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeFeaturedOffers'),
      homePromotion: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomePromotion'),
      homeRanking: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeRanking'),
      homeRankingInfoIcon: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeRankingInfoIcon'),
      homeSurvey: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'HomeSurvey'),
      newsBox: this.skin.isItemVisible('Global', 'NewsBox'),
      paymentHistoryWidget: this.skin.isItemVisible(SKIN_COMPONENT_NAME, 'PaymentHistoryWidget'),
    };
  }

  getHomeYourAffiliateManagerText() {
    return this.skin.getPropertyValue('Home', 'yourAffiliateManager', 'text') || 'Your Affiliate Manager';
  }

  getHomeAffiliateRankingText() {
    return this.skin.getPropertyValue('Home', 'affiliateRanking', 'text') || 'Affiliate Ranking';
  }

  handleNewDateRangeStatistics(time) {
    const { dispatch } = this.props;

    dispatch(fetchDailyStatistics(time));
  }

  render() {
    const {
      affiliateManager,
      affInfos,
      affRank,
      affStatus,
      blog,
      blogIsFetching,
      currentQuarter,
      customAffInfos,
      dailyStatistics,
      dailyStatisticsIsFetching,
      dailyStatisticsTotals,
      dispatch,
      featuredOffers,
      featuredOffersIsFetching,
      isFetchingUserInfos,
      isOverrided,
      mainMenuIsOpen,
      minimumPayout,
      news,
      newsIsFetching,
      paymentHistory,
      paymentTerms,
      profileCompleted,
      promo,
      promoIsFetching,
      minimumPayoutAmount,
      nextPaymentAmount,
      currentPeriodPayout,
      lastPeriodPayout,
      isCurrentPeriodPayout,
    } = this.props;

    const IS_VISIBLE = this.getHomeItemsVisibility();
    const timestampNPS = customAffInfos ? moment(`${customAffInfos.net_promoter_score_timestamp}`) : undefined;
    const timestampDismissedNPS = customAffInfos ? moment(`${customAffInfos.net_promoter_score_timestamp_dismissed}`) : undefined;
    const getPaymentHistoryHyperlink = (communicationStatus) => {
      switch (communicationStatus) {
        case 'both':
        case 'profile':
          return {
            href: '/profile/user-details',
            text: <FormattedMessage {...messages.genericTextGoToUserDetails} />,
          };
        case 'payment':
          return {
            href: '/profile/billing-infos',
            text: <FormattedMessage {...messages.genericTextGoToBillingInformation} />,
          };
        default:
          return {
            href: '/profile/payment-history',
            text: <FormattedMessage {...messages.genericTextSeeDetails} />,
            gtmEvent: () => {
              Gtm.event('dashboard', 'Click', 'payment history - see details');
            },
          };
      }
    };

    const isShownLastYear = timestampNPS < moment().subtract(NPS.DAYS_WITHOUT_PROMPT, 'day');
    const isShownLastMonth = timestampDismissedNPS < moment().subtract(NPS.DAYS_WITHOUT_PROMPT_RESPONSE, 'day');
    const isDismiss = timestampNPS <= timestampDismissedNPS;

    return (
      <div className="container-fluid app-container" id="home-page">
        {IS_VISIBLE.homeSurvey &&
        affInfos.days_registered > 30 &&
        currentQuarter === (affInfos.id % 4) + 1 &&
        (isNaN(timestampNPS) || isShownLastYear || (isShownLastMonth && isDismiss)) ? (
          <HomeSurvey affId={affInfos.id} dispatch={dispatch} isOverrided={isOverrided} mainMenuIsOpen={mainMenuIsOpen} />
        ) : null}
        <div className="row mobile-ordering-box">
          <Container
            className="z-depth-2 col-xs-12 hidden-md hidden-lg hidden-xlg paymenthistorygraph-mobile mobile-order-6"
            id="paymentGraph"
            loading={false}
          >
            <PaymentHistoryGraph
              currentPeriodPayout={currentPeriodPayout}
              hasReachedMinimumPayout={paymentHistory.asReachedMinimumPayout}
              isCurrentPeriodPayout={isCurrentPeriodPayout}
              lastPeriodHasInvoice={paymentHistory.lastPeriodHasInvoice}
              lastPeriodPayout={lastPeriodPayout}
              minimumPayoutAmount={minimumPayoutAmount}
              nextPaymentAmount={nextPaymentAmount}
              paymentTerms={paymentTerms}
            />
          </Container>

          {news.active && IS_VISIBLE.newsBox ? (
            <div className="mobile-order-2" ref={(el) => (this.colsElements.newsbox = el)}>
              <Container
                className="col-xs-12 col-md-12 col-lg-12 shorter-xlg-col-12 z-depth-2"
                id="newsbox"
                loading={newsIsFetching}
              >
                <NewsBox content={news} />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.homePromotion ? (
            <div
              className="mobile-order-2"
              ref={(el) => {
                this.colsElements.promotion = el;
              }}
            >
              <Container
                className="col-xs-12 col-md-12 col-lg-12 shorter-xlg-col-6 z-depth-2 black-title"
                id="promotion"
                loading={promoIsFetching}
              >
                <HomePromotion content={promo} />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.homeRanking ? (
            <div
              className="mobile-order-3"
              ref={(el) => {
                this.colsElements.ranking = el;
              }}
            >
              <Container
                className="col-xs-12 col-sm-6 col-md-6 col-lg-6 shorter-lg-col-6 shorter-xlg-col-3 shorter-md-col-6 shorter-sm-col-6 z-depth-2 black-title "
                icon="&#xE01D;"
                id="ranking"
                info={
                  IS_VISIBLE.homeRanking
                    ? {
                        title: 'Click here to know more about ranking',
                        url: 'https://support.crakrevenue.com/knowledge-base/crakrevenue-quarterly-affiliate-ranking-update-frequency/',
                        gtmEvent: () => {
                          Gtm.event('dashboard', 'Click', 'affiliate ranking info');
                        },
                      }
                    : false
                }
                loading={isFetchingUserInfos || dailyStatisticsIsFetching}
                title={this.getHomeAffiliateRankingText()}
              >
                <HomeRanking affRank={affRank} affStatus={affStatus} skinConfigurations={this.skinConfigurations} />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.paymentHistoryWidget ? (
            <div
              className="mobile-order-4"
              ref={(el) => {
                this.colsElements.payment = el;
              }}
            >
              <Container
                className="hidden-xs col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xlg-3 z-depth-2 black-title"
                icon="&#xE84F;"
                id="payment"
                link={getPaymentHistoryHyperlink(profileCompleted.communicationStatus)}
                loading={isFetchingUserInfos}
                title={<FormattedMessage {...messages.homePaymentHistoryTitle} />}
              >
                <PaymentHistoryWidget
                  minimumPayout={minimumPayout}
                  paymentHistory={paymentHistory}
                  paymentTerms={paymentTerms}
                  profileCommunicationStatus={profileCompleted.communicationStatus}
                />
              </Container>
              <Container className="payment-mobile visible-xs col-xs-12 z-depth-2 bg-primary-color black-title">
                <PaymentHistoryWidgetContainer
                  minimumPayout={minimumPayout}
                  paymentHistory={paymentHistory}
                  paymentTerms={paymentTerms}
                  profileCommunicationStatus={profileCompleted.communicationStatus}
                />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.homeFeaturedOffers ? (
            <div
              className="mobile-order-5"
              ref={(el) => {
                this.colsElements.featuredOffers = el;
              }}
            >
              <Container
                Svg={Star}
                className="col-xs-12 col-sm-12 col-md-4 col-lg-4 shorter-xlg-col-4 shorter-lg-col-4 shorter-md-col-4 z-depth-2 black-title "
                id="featured-offers"
                link={{
                  href: '/offers',
                  text: 'Browse all offers',
                  gtmEvent: () => {
                    Gtm.event('dashboard', 'Click', 'browse all offers');
                  },
                }}
                loading={featuredOffersIsFetching}
                title={<FormattedMessage {...messages.homeFeaturedOffers} />}
              >
                <HomeFeaturedOffers featuredOffers={featuredOffers} />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.homeBlog ? (
            <div
              className="mobile-order-7"
              ref={(el) => {
                this.colsElements.blog = el;
              }}
            >
              <Container
                className="col-xs-12 col-sm-12 col-md-8 col-lg-8 z-depth-2 black-title"
                icon="rss_feed"
                id="blog"
                link={{
                  href: 'http://crakrevenue.com/blog',
                  text: 'Read more on the blog',
                  target: '_blank',
                  gtmEvent: () => {
                    Gtm.event('dashboard', 'Click', 'read more on the blog');
                  },
                }}
                loading={blogIsFetching}
                title={<FormattedMessage {...messages.homeLatestBlogPosts} />}
              >
                <HomeBlog latestPosts={blog} />
              </Container>
            </div>
          ) : null}

          {IS_VISIBLE.homeDailyStatistics ? (
            <Container
              className="col-xs-12 col-sm-6 col-md-6 col-lg-9 col-xlg-9 z-depth-2 shorter-xlg-col-9 shorter-lg-col-9 shorter-md-col-6 shorter-sm-col-6 black-title mobile-order-1"
              icon="today"
              loading={dailyStatisticsIsFetching}
              title={<FormattedMessage {...messages.homeRecentStatistics} />}
            >
              <div
                ref={(el) => {
                  this.colsElements.statistics = el;
                }}
              >
                <HomeDailyStatistics
                  dailyStatistics={dailyStatistics}
                  dailyStatisticsIsFetching={dailyStatisticsIsFetching}
                  dailyStatisticsTotals={dailyStatisticsTotals}
                  handleNewDateRangeStatistics={this.handleNewDateRangeStatistics}
                  resize={this.rowResize}
                />
              </div>
            </Container>
          ) : null}

          {IS_VISIBLE.homeAccountManager.container ? (
            <Container
              className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xlg-3 z-depth-2 black-title mobile-order-8"
              icon="headset_mic"
              loading={isFetchingUserInfos}
              title={this.getHomeYourAffiliateManagerText()}
            >
              <div ref={(el) => (this.colsElements.affManager = el)}>
                <HomeAccountManager
                  affiliateManager={affiliateManager || {}}
                  className="dashboard-account-manager"
                  isVisible={IS_VISIBLE.homeAccountManager['sub-elements']}
                  skinConfigurations={this.skinConfigurations}
                />
              </div>
            </Container>
          ) : null}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  affInfos: PropTypes.object,
  affRank: PropTypes.object.isRequired,
  affStatus: PropTypes.object,
  affiliateManager: PropTypes.any,
  blog: PropTypes.array.isRequired,
  blogIsFetching: PropTypes.bool,
  currentQuarter: PropTypes.number.isRequired,
  customAffInfos: PropTypes.object,
  dailyStatistics: PropTypes.array,
  dailyStatisticsIsFetching: PropTypes.bool,
  dailyStatisticsTotals: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  featuredOffers: PropTypes.array.isRequired,
  featuredOffersIsFetching: PropTypes.bool,
  isCurrentPeriodPayout: PropTypes.any,
  isFetchingUserInfos: PropTypes.bool,
  isOverrided: PropTypes.bool,
  mainMenuIsOpen: PropTypes.bool.isRequired,
  minimumPayout: PropTypes.any.isRequired,
  news: PropTypes.object.isRequired,
  newsIsFetching: PropTypes.bool,
  paymentHistory: PropTypes.object.isRequired,
  paymentTerms: PropTypes.string.isRequired,
  profilSettings: PropTypes.object,
  profileCompleted: PropTypes.object,
  promo: PropTypes.object.isRequired,
  promoIsFetching: PropTypes.bool,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  affiliateManager: state.profile.data.affiliateManager,
  affInfos: state.profile.data.affInfos,
  affRank: state.profile.data.affRank,
  blog: state.blog.data,
  blogIsFetching: state.blog.isFetching,
  currentPeriodPayout:
    (state.home.currentPeriodPayouts.totals &&
      state.home.currentPeriodPayouts.totals.Stat &&
      +parseFloat(state.home.currentPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
    0,
  currentQuarter: state.application.currentQuarter,
  customAffInfos: state.profile.data.customProfile.affiliate,
  dailyStatistics: state.home.dailyStatistics,
  dailyStatisticsIsFetching: state.home.dailyStatisticsIsFetching,
  dailyStatisticsTotals: state.home.dailyStatisticsTotals,
  featuredOffers: state.topoffers.top.homeOffers,
  featuredOffersIsFetching: state.home.featuredOffersIsFetching,
  isFetchingUserInfos: state.profile.isFetching,
  isOverrided: state.profile.data.isOverrided,
  mainMenuIsOpen: state.application.ui.mainMenuIsOpen,
  minimumPayout: state.profile.data.customProfile.affiliate.minimum_payout
    ? state.profile.data.customProfile.affiliate.minimum_payout.amount || ''
    : '',
  minimumPayoutAmount: +parseFloat(state.profile.data.customProfile.affiliate.minimum_payout.amount).toFixed(2) || 0,
  nextPaymentAmount: +parseFloat(state.profile.data.paymentHistory.next.amount).toFixed(2) || 0,
  lastPeriodPayout:
    (state.home.lastPeriodPayouts.totals &&
      state.home.lastPeriodPayouts.totals.Stat &&
      +parseFloat(state.home.lastPeriodPayouts.totals.Stat.payout).toFixed(2)) ||
    0,
  news: state.news.data,
  newsIsFetching: state.news.isFetching,
  paymentHistory: state.profile.data.paymentHistory,
  paymentTerms: state.profile.data.customProfile.affiliate.payment_term || '',
  profileCompleted: state.profileCompleted,
  profilSettings: state.profile.data.settings,
  promo: state.promo.data,
  promoIsFetching: state.promo.isFetching,
  skinConfigurations: state.skinConfigurations.data,
  timestampReceived: state.promo.timestampReceived,
  isCurrentPeriodPayout: state.home.currentPeriodPayouts.totals || null,
}))(Home);
