import consts from '../utils/consts';

const {
  ACTIONS: { GAMIFICATION },
} = consts;

const initialState = {
  eventsDataIsFetching: false,
  badgeDataIsFetching: false,
  request: null,
  eventsData: {},
  badgeData: {},
};

export const gamification = (state = initialState, action) => {
  switch (action.type) {
    case GAMIFICATION.REQUEST_EVENTS:
      return {
        ...state,
        eventsDataIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_EVENTS: {
      let notificationLoginStreak = action?.payload?.notification;
      let notificationFirstPayment = undefined;

      if (!notificationLoginStreak) {
        const notifications = action?.payload;
        notificationLoginStreak = notifications?.find((notification) => notification.type === 'login_streak');
        notificationFirstPayment = notifications?.find((notification) => notification.type === 'first_payment');
      }

      return {
        ...state,
        eventsDataIsFetching: false,
        eventsData: {
          notification: notificationLoginStreak,
          notificationFirstPayment,
        },
      };
    }
    case GAMIFICATION.REQUEST_BADGES:
      return {
        ...state,
        badgeDataIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_BADGES:
      return {
        ...state,
        badgeDataIsFetching: false,
        badgeData: action.payload,
      };
    default:
      return state;
  }
};
