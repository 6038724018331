import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import TellUsAboutYou from '../TellUsAboutYou.react';
import { fetchOffersCategories } from '../../../../components/Application/actions/application-actions';
import { createUser } from '../../../../components/Login/actions/signup-actions';
import { getCookie } from '../../../../utils/helpers';
import { Gtm } from '../../../../utils/gtm';
import socialsList from '../../../../utils/list-options/socials';
import config from '../../../../config';

const TellUsAboutYouContainer = (props) => {
  const { dispatch, userInfo, signupToken, categoriesList, countriesList, skinConfigurations } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [useNewForm, setUseNewForm] = useState(undefined);

  const redirectToSignupFormV2 = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    const paramsToKeep = ['regionOverride'];
    const newSearchParams = new URLSearchParams();

    for (const [key, value] of searchParams.entries()) {
      if (paramsToKeep.includes(key)) {
        newSearchParams.append(key, value);
      }
    }

    let newUrl = '/access/about';

    if (newSearchParams.toString()) {
      newUrl += `?${newSearchParams.toString()}`;
    }
    browserHistory.push(newUrl);
  };

  // add function to give time to convert to setup
  const checkWindowConvert = (timeout = 1000) =>
    new Promise((resolve) => {
      const interval = 200;
      let timer = null;
      let elapsedTime = 0;

      const check = () => {
        elapsedTime += interval;
        if (window.convert !== undefined || elapsedTime >= timeout) {
          clearInterval(timer);
          resolve(window.convert);
        }
      };

      timer = setInterval(check, interval);
    });

  useEffect(() => {
    if (userInfo.account_status !== 'has_to_complete_info') {
      browserHistory.push({ pathname: '/login' });
    } else {
      const form = new URL(window.location.href).searchParams.get('form');
      if (form !== '1') {
        checkWindowConvert(1000).then(() => {
          const currentExperience = window.convert?.currentData?.experiences[config.SIGNUP_FORM_EXPERIENCE_ID];
          setUseNewForm(!currentExperience ? true : currentExperience?.variation?.id === config.SIGNUP_FORM_VARIATION_ID);
        });
      } else {
        setUseNewForm(false);
      }
    }
  }, [dispatch, userInfo.account_status]);

  useEffect(() => {
    if (useNewForm !== undefined) {
      if (useNewForm) {
        redirectToSignupFormV2();
      } else {
        dispatch(fetchOffersCategories());
        setIsLoading(false);
      }
    }
  }, [useNewForm, dispatch]);

  const socialBuilder = (socialPicked, handle) => {
    const social = socialsList.find((social) => social.value === socialPicked);
    return `${social.url}${handle}`;
  };

  const formatBudget = (formData) => formData?.budget?.toString()?.replaceAll(',', '')?.replaceAll('$', '');

  const formatUrl = (url) => {
    if (!url) {
      return '';
    }
    const protocol = 'https://';
    return !url.match(/^(?:(?:https?|ftp):\/\/)/i) ? protocol + url : url;
  };

  const formatPromotingUrl = (formData) => {
    switch (formData.accountType) {
      case 'Webmaster':
        return `${formatUrl(formData.website1)}${formatUrl(formData.website2) !== '' ? `;${formatUrl(formData.website2)}` : ''}${
          formatUrl(formData.website3) !== '' ? `;${formatUrl(formData.website3)}` : ''
        }`;
      case 'Social Marketer':
      case 'Influencer / Content Creator':
        return `${socialBuilder(formData.social1, formData.handle1)}${
          formData.handle2 !== '' && formData.handle2 !== undefined ? `;${socialBuilder(formData.social2, formData.handle2)}` : ''
        }${
          formData.handle3 !== '' && formData.handle3 !== undefined ? `;${socialBuilder(formData.social3, formData.handle3)}` : ''
        }`;
      case 'Media Buyer':
        return formatBudget(formData);
      case 'Affiliate Network':
      case 'Ad Network':
        return formatUrl(formData.network);
    }
  };

  const formatHowYouHear = (formData) => `${formData.howYouHear} - ${formData.specify}`;

  const handleFormSubmit = (formData) => {
    const referralId = userInfo.referral_id || getCookie('referralId');
    const promotingUrl = formatPromotingUrl(formData);
    const howYouHear = formatHowYouHear(formData);
    delete formData.website1;
    delete formData.website2;
    delete formData.website3;
    delete formData.social1;
    delete formData.social3;
    delete formData.social2;
    delete formData.handle1;
    delete formData.handle2;
    delete formData.handle3;
    delete formData.howYouHear;
    delete formData.specify;
    delete formData.budget;
    if (!userInfo.utm_campaign) {
      userInfo.utm_campaign = getCookie('cr_utm_campaign');
    }
    if (!userInfo.utm_medium) {
      userInfo.utm_medium = getCookie('cr_utm_medium');
    }
    if (!userInfo.utm_source) {
      userInfo.utm_source = getCookie('cr_utm_source');
    }
    if (!userInfo.utm_term) {
      userInfo.utm_term = getCookie('cr_utm_term');
    }
    if (!userInfo.utm_content) {
      userInfo.utm_content = getCookie('cr_utm_content');
    }
    dispatch(
      createUser({
        ...userInfo,
        ...formData,
        howYouHear,
        promotingUrl,
        referralId,
        signupToken,
        gaClientId: getCookie('_ga'),
      })
    );
    Gtm.event('Register New Form', 'Complete', 'Step 2');
    browserHistory.push({ pathname: '/access/registered' });
  };

  let verticals = categoriesList
    .filter((category) => category.name.indexOf('Vertical') === 0)
    .map((vertical) => ({
      ...vertical,
      name: vertical.name.split('Vertical - ')[1],
    }));

  if (skinConfigurations.name === 'crakrevenue') {
    let unwantedVerticalIds;
    if (process.env.REACT_APP_ENV === 'prod') {
      unwantedVerticalIds = ['1', '391'];
    } else {
      unwantedVerticalIds = ['6', '67'];
    }
    verticals = verticals.filter((item) => !unwantedVerticalIds.includes(item.id));
  }

  if (!isLoading) {
    return <TellUsAboutYou {...props} countriesList={countriesList} onSubmit={handleFormSubmit} verticals={verticals} />;
  } else {
    return (
      <div className="simple-signup__container path-access-connection">
        <div className="simple-signup__container__box__splash">
          <div id="splash-whale">
            <img
              alt="loading gif"
              id="splash-whale-gif"
              src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
            />
          </div>
        </div>
      </div>
    );
  }
};

TellUsAboutYouContainer.propTypes = {
  categoriesList: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  signupToken: PropTypes.string,
  skinConfigurations: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default connect((state) => ({
  categoriesList: state.application.lists.offersCategories,
  countriesList: state.application.lists.countries,
  skinConfigurations: state.skinConfigurations.data,
  userInfo: state.signup.userInfo,
  signupToken: state.signup.signupToken,
  hoQuestions: state.signup.hoQuestions,
}))(TellUsAboutYouContainer);
