import { Link } from 'react-router';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import messages from '../../../i18n/base-en.js';

import LinkIcon from '../../../components/reusables/svg/LinkIcon.react';
import ChooseVerticalIcon from '../../../components/reusables/svg/ChooseVerticalIcon.react';
import RankGamificationIcon from '../../../components/reusables/svg/RankGamificationIcon.react';

const HomeRankingNotRanked = () => {
  const notRankedActions = [
    {
      icon: <LinkIcon />,
      caption: 'Unleash our Smartlinks, a turnkey solution designed to maximize your earnings! ',
      cta: messages.homeNotRankedActionCTAs.smartLink,
      link: '/smartlink',
    },
    {
      icon: <ChooseVerticalIcon />,
      caption: 'Choose among 500+ exclusive and high-paying offers to promote in hot verticals. ',
      cta: messages.homeNotRankedActionCTAs.offers,
      link: '/offers',
    },
    {
      icon: <RankGamificationIcon />,
      caption: 'Enjoy exclusive perks, badges and rewards as you climb the ranks! ',
      cta: messages.homeNotRankedActionCTAs.learnMore,
      externalLink: 'https://support.crakrevenue.com/knowledge-base/crakrevenue-achievement-program/',
    },
  ];

  return (
    <div className="ranking-inner not-ranked">
      <h3 className="not-ranked-title">
        <small>
          <FormattedMessage {...messages.homeNotRankedSubtitle} />
        </small>
        <FormattedMessage {...messages.homeNotRankedTitle} />
      </h3>
      <ul className="actions">
        {notRankedActions.map((action) => (
          <li key={uuidv4()}>
            <div className="actions-item">
              <div className="action-icon">{action.icon}</div>
              <p className="action-caption">
                {action.caption}
                <span className="more primary-color">
                  {!!action.link && (
                    <Link onlyActiveOnIndex={false} to={action.link}>
                      <FormattedHTMLMessage {...action.cta} />
                    </Link>
                  )}
                  {!!action.externalLink && (
                    <a href={action.externalLink} rel="noreferrer" target="_blank">
                      <FormattedHTMLMessage {...action.cta} />
                    </a>
                  )}
                </span>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HomeRankingNotRanked;
