import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../../utils/gtm';
import messages from '../../../i18n/base-en.js';

import { SelectMulti } from '../../../components/reusables';
import SkinConfigurationsUtils from '../../../utils/SkinConfigurationsUtils';

const getBaseFiltersVisibility = (skin) => ({
  COUNTRY_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.country'),
  OFFER_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.offer'),
  OFFER_URL_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.offerUrl'),
  VERTICAL_IS_VISIBLE: skin.isItemVisible('Statistics', 'StatisticsBaseFilters.vertical'),
});
const StatisticsBaseFilters = ({
  categories,
  countries,
  fields,
  intl,
  isDisabled,
  offersList,
  offersUrlList,
  skinConfigurations,
}) => {
  const BASE_FILTERS = getBaseFiltersVisibility(new SkinConfigurationsUtils(skinConfigurations));

  const sortOffers = () => {
    const sortable = [];

    Object.keys(offersList).forEach((key) => {
      sortable.push(offersList[key]);
    });

    sortable.sort((a, b) =>
      b.Offer.name ? a.Offer.name?.replace(/\s/g, '').localeCompare(b.Offer.name.replace(/\s/g, '')) : -1
    );
    return sortable;
  };

  const handleGtmEventVertical = (selectedIds) => {
    const eventLabel = categories?.filter(({ id }) => selectedIds.includes(id));
    Gtm.event('statistics', 'Click', `vertical - ${eventLabel.map((category) => category.name.split('Vertical - ')[1])}`);
  };

  const handleGtmEventOffers = (selectedIds) => {
    const eventLabel = selectedIds.map((id) => offersList[id]);
    Gtm.event('statistics', 'Click', `offers - ${eventLabel.map((offer) => offer.Offer.name)}`);
  };

  const handleGtmEventLP = (landingNames) => {
    Gtm.event('statistics', 'Click', `landing page - ${landingNames}`);
  };

  const handleGtmEventCountries = (countryName) => {
    Gtm.event('statistics', 'Click', `country - ${countryName}`);
  };

  return (
    <div className="row baseFilters">
      {BASE_FILTERS.VERTICAL_IS_VISIBLE ? (
        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
          <SelectMulti
            disabled={isDisabled}
            field={fields.vertical}
            filterCategory="Vertical"
            filterName="Category.id"
            handleGtmEvent={handleGtmEventVertical}
            id="vertical"
            label={messages.genericTextVertical.description}
            tabIndex={20}
            value={fields.vertical.value}
          >
            <option disabled value="">
              {intl.formatMessage(messages.genericTextChoose)}
            </option>
            {categories
              .filter((category) => category.name.indexOf('Vertical') === 0)
              .map((category) => (
                <option data-category="Vertical" key={`vertical-${category.id}`} value={category.id}>
                  {category.name.split('Vertical - ')[1]}
                </option>
              ))}
          </SelectMulti>
        </div>
      ) : null}

      {BASE_FILTERS.OFFER_IS_VISIBLE ? (
        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
          <SelectMulti
            canSearch
            disabled={isDisabled || Object.keys(offersList).length === 0}
            field={fields.offer}
            filterCategory="Offer"
            filterName="Offer.id"
            handleGtmEvent={handleGtmEventOffers}
            id="offer"
            label={messages.genericTextOffers.description}
            tabIndex={40}
            value={fields.offer.value}
          >
            <option disabled value="">
              {intl.formatMessage(messages.genericTextChoose)}
            </option>
            {sortOffers().map((offer) => (
              <option data-category="Offer" key={`offer-${offer.Offer.id}`} value={offer.Offer.id}>
                {`${offer.Offer.name} (${offer.Offer.id})`}
              </option>
            ))}
          </SelectMulti>
        </div>
      ) : null}

      {BASE_FILTERS.OFFER_IS_VISIBLE && BASE_FILTERS.OFFER_URL_IS_VISIBLE ? (
        <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
          <SelectMulti
            disabled={isDisabled || offersUrlList.length === 0}
            field={fields.offerUrl}
            filterCategory="Landing page"
            filterName="OfferUrl.name"
            handleGtmEvent={handleGtmEventLP}
            id="offerUrl"
            label={messages.statisticsLandingPage.description}
            tabIndex={50}
            tooltip={offersUrlList.length === 0 ? 'Please select an offer first.' : ''}
            value={fields.offerUrl.value}
          >
            <option disabled value="">
              {intl.formatMessage(messages.genericTextChoose)}
            </option>
            {offersUrlList.map((offerUrl) => (
              <option data-category="offer_url" key={`offer-url-${offerUrl.id}`} value={offerUrl.name}>
                {offerUrl.name}
              </option>
            ))}
          </SelectMulti>
        </div>
      ) : null}

      {BASE_FILTERS.COUNTRY_IS_VISIBLE ? (
        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 filterInput">
          <SelectMulti
            canSearch
            disabled={isDisabled}
            field={fields.country}
            filterCategory="Country"
            filterName="Country.name"
            handleGtmEvent={handleGtmEventCountries}
            id="country"
            label={messages.genericTextCountry.description}
            tabIndex={60}
            value={fields.country.value}
          >
            <option disabled value="">
              {intl.formatMessage(messages.genericTextChoose)}
            </option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </SelectMulti>
        </div>
      ) : null}
    </div>
  );
};

StatisticsBaseFilters.propTypes = {
  categories: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  offersList: PropTypes.object.isRequired,
  offersUrlList: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default injectIntl(
  connect((state) => ({
    skinConfigurations: state.skinConfigurations.data,
  }))(StatisticsBaseFilters)
);
